import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@andes/button';
import ChevronUp24 from '@andes/icons/ChevronUp24';

import Tracker from '../../Utils/tracker';
import getTextsByCountry from '../../../texts/getTexts';

const Footer = ({
  i18n,
  background,
  lang,
  analyticsLabel,
  country,
  melidataPath,
}) => (
  <section className={`footer ${background}`}>
    <div className="footer--content">
      <h1 className="footer--content-title">
        {getTextsByCountry(country, 'Footer_agencies_title', i18n)}
      </h1>
      <Button
        className="icon-container"
        data-testid="scroll-top-button"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <ChevronUp24 className="icon" color="white" />
      </Button>
      <p className="footer--content-details">
        {getTextsByCountry(country, 'footer_description', i18n)}
      </p>
      <Button
        className="footer--content-action"
        href={`/partners/agencies/${lang}/subscribe`}
        hierarchy="transparent"
        onClick={() => {
          Tracker.trackAnalyticsEvent({ label: analyticsLabel });
          Tracker.melidataTrack(melidataPath, { event: analyticsLabel });
        }}
      >
        {i18n.gettext('Quiero formar parte')}
      </Button>
    </div>
  </section>
);

Footer.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  background: PropTypes.string,
  lang: PropTypes.string,
  analyticsLabel: PropTypes.string.isRequired,
  country: PropTypes.string,
  melidataPath: PropTypes.string,
};

export default Footer;
