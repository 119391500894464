const FAQQuestions = (i18n) => [
  {
    id: '1',
    title: i18n.gettext('¿Qué es el Programa de Partners de Mercado Pago?'),
    response: i18n.gettext(
      'El Programa de Partners está pensado para que las agencias integradoras tengan acceso a una serie de ventajas exclusivas y ayudarlas a estrechar aún más la relación con sus clientes y con nuestro equipo de Mercado Pago.',
    ),
    sites: ['MLB'],
  },
  {
    id: '2',
    title: i18n.gettext('¿Cómo hago para participar del programa?'),
    response: i18n.gettext(
      'Envíanos algunos datos sobre tu agencia a través del formulario. Nuestro equipo comercial analizará tu perfil y, si se aprueba, nos pondremos en contacto contigo para tener una reunión y hacer oficial nuestra sociedad.',
    ),
    sites: ['MLB'],
  },
  {
    id: '3',
    title: i18n.gettext('¿Qué es el Partners Program de Mercado Pago?'),
    response: i18n.gettext(
      'El Partners Program está pensado para que las agencias integradoras tengan acceso a una serie de ventajas exclusivas y ayudarlas a estrechar aún más la relación con sus clientes y con nuestro equipo de Mercado Pago.',
    ),
    sites: ['MLA', 'MLM'],
  },
  {
    id: '4',
    title: i18n.gettext('¿Cómo hago para participar del programa?'),
    response: i18n.gettext(
      'Envíanos algunos datos sobre tu agencia a través del formulario. Nuestro equipo comercial analizará tu perfil y, si se aprueba, nos pondremos en contacto con vos para tener una reunión y hacer oficial nuestra sociedad.',
    ),
    sites: ['MLA', 'MLM'],
  },
  {
    id: '5',
    title: i18n.gettext('¿Quienes pueden participar?'),
    response: i18n.gettext(
      'El programa está pensado para las agencias que implementan tiendas virtuales y quieren iniciar o aumentar su relación con Mercado Pago.',
    ),
  },
];

const getFAQQuestions = (i18n, siteId) =>
  FAQQuestions(i18n).filter(
    (item) => !item.sites || item.sites.includes(siteId.toUpperCase()),
  );

export { getFAQQuestions, FAQQuestions };
