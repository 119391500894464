const paymentSolution = (i18n) => [
  {
    id: '0',
    image: 'Cart.svg',
    title: i18n.gettext('Integración directa'),
    description: i18n.gettext(
      'Integración directa de checkouts en sitios, tiendas online y landing pages.',
    ),
    className: 'medium-width',
  },
  {
    id: '1',
    image: 'Bag.svg',
    title: i18n.gettext('Integración de plataforma'),
    description: i18n.gettext(
      'Integración de plugin y checkout para tiendas creadas en plataformas de e-commerce.',
    ),
    className: 'large-width',
  },
  {
    id: '2',
    image: 'Card.svg',
    title: i18n.gettext('Otros productos'),
    description: i18n.gettext(
      'Implementación de diferentes soluciones de pago.',
    ),
    className: 'small-width',
  },
];

const getPayment = (i18n) => paymentSolution(i18n);

export { getPayment };
