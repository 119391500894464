import React from 'react';
import PropTypes from 'prop-types';

import ClientBenefitsContent from './components/ClientBenefitsContent';
import ClientBenefitsImage from './components/ClientBenefitsImage';

const ClientBenefits = ({ i18n, clientBenefits }) => (
  <section className="clients--benefits-container">
    <div className="clients--benefits">
      <ClientBenefitsContent clientBenefits={clientBenefits} i18n={i18n} />
      <ClientBenefitsImage />
    </div>
  </section>
);

ClientBenefits.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  clientBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
};

export default ClientBenefits;
