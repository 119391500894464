import React from 'react';
import PropTypes from 'prop-types';

const EventsContent = ({ i18n }) => (
  <div className="events--content">
    <h2 className="events--content-title">
      {i18n.gettext(
        'Participa en los eventos de las agencias asociadas y mantente al tanto de las novedades de Mercado Pago',
      )}
    </h2>
    <p className="events--content-details">
      {i18n.gettext(
        'Los eventos promocionados para las agencias asociadas al programa reúnen a profesionales de referencia para intercambiar experiencias con nuestra comunidad.',
      )}
    </p>
    <br />
    <p className="events--content-details">
      {i18n.gettext(
        'Es la oportunidad de actualizar tus conocimientos en materia de medios de pago y otros asuntos relevantes para tus negocios y los de tus clientes.',
      )}
    </p>
  </div>
);

EventsContent.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
};

export default EventsContent;
