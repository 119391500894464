import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

const EventsImage = ({ isMobile, country }) => (
  <Image
    className="events--image"
    src={`agencies/events/events_image_${country}${
      isMobile ? '_mobile' : ''
    }_v1.png?v=1`}
    alt=""
    // onContextMenu={() => false}
  />
);

EventsImage.propTypes = {
  isMobile: PropTypes.bool,
  country: PropTypes.string,
};

export default EventsImage;
