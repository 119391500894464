import React from 'react';
import PropTypes from 'prop-types';

import FeedbackPositive24 from '@andes/icons/FeedbackPositive24';

const ClientBenefitsContent = ({ clientBenefits, i18n }) => (
  <div className="clients--benefits--content">
    <div className="clients--benefits-container-title">
      <h2 className="clients--benefits-container-title">
        {i18n.gettext(
          'Las agencias partners pueden ofrecer ventajas a sus clientes que utilizan las soluciones de pago de Mercado Pago ',
        )}
      </h2>
    </div>
    {clientBenefits.map((clientBenefit) => (
      <div
        className="clients--benefits--content-container"
        key={clientBenefit.id}
      >
        <div>
          <FeedbackPositive24 color="#fff" />
        </div>
        <div>
          <h2 className="clients--benefits--content-container-title">
            {clientBenefit.title}
          </h2>
          <p className="clients--benefits--content-container-details">
            {clientBenefit.description}
          </p>
        </div>
      </div>
    ))}
  </div>
);

ClientBenefitsContent.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  clientBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
};

export default ClientBenefitsContent;
