import React from 'react';

import { Image } from 'nordic/image';

const HeaderImage = () => (
  <Image
    className="header--image"
    src="agencies/header_image_v2.png?v=1"
    alt=""
    // onContextMenu={() => false}
  />
);

export default HeaderImage;
