import React from 'react';
import PropTypes from 'prop-types';

import LevelsContent from './components/LevelsContent';
import LevelsImage from './components/LevelsImage';

const Levels = ({ i18n, lang, melidataPath }) => (
  <section className="levels-container">
    <div className="levels">
      <LevelsImage />
      <LevelsContent i18n={i18n} lang={lang} melidataPath={melidataPath} />
    </div>
  </section>
);

Levels.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  lang: PropTypes.string,
  melidataPath: PropTypes.string,
};

export default Levels;
