import React from 'react';
import PropTypes from 'prop-types';

import EventsContent from './components/EventsContent';
import EventsImage from './components/EventsImage';

const Events = ({ i18n, isMobile, country }) => (
  <section className="events-container">
    <div className="events">
      <EventsContent i18n={i18n} />
      <EventsImage isMobile={isMobile} country={country} />
    </div>
  </section>
);

Events.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  isMobile: PropTypes.bool,
  country: PropTypes.string,
};

export default Events;
