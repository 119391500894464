import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@andes/button';

import Tracker from '../../../../Utils/tracker';
import getTextsByCountry from '../../../../../texts/getTexts';

const HeaderContent = ({ i18n, lang, country, melidataPath }) => (
  <div className="header--content">
    <span className="header--content-program" label="agencies">
      {i18n.gettext('Programa de partners')}
    </span>
    <h1 className="header--content-title">
      {i18n.gettext('Conviertete en una agencia partner de Mercado Pago')}
    </h1>
    <p className="header--content-details">
      {getTextsByCountry(country, 'headerHero_description', i18n)}
    </p>
    <Button
      className="header--content-action"
      href={`/partners/agencies/${lang}/subscribe`}
      hierarchy="transparent"
      onClick={() => {
        Tracker.trackAnalyticsEvent({
          label: 'ACQUISITION_LANDING_HEADER_ACTION_CLICK',
        });
        Tracker.melidataTrack(melidataPath, {
          event: 'ACQUISITION_LANDING_HEADER_ACTION_CLICK',
        });
      }}
    >
      {i18n.gettext('Soy una agencia y quiero participar')}
    </Button>
  </div>
);

HeaderContent.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  lang: PropTypes.string,
  country: PropTypes.string,
  melidataPath: PropTypes.string,
};

export default HeaderContent;
