const textsMx = (i18n) => ({
  HeaderContent_details: i18n.gettext(
    'Recibe dinero con las integraciones que hagas e ingresa a nuestra comunidad global exclusiva, donde tendrás soporte de otros profesionales y acceso a novedades especiales.',
  ),
  EconomicContent_details: i18n.gettext(
    'Te pagamos una comisión en dinero por cada cuenta nueva que integres con nuestras soluciones de checkout.',
  ),
  headerHero_description: i18n.gettext(
    'Ten acceso a tasas y comisiones diferenciadas, soporte técnico exclusivo y otros beneficios para tu agencia y tus clientes. ¡Todo gratis!',
  ),
  steps_3: i18n.gettext(
    'Tu agencia recibe un kit de bienvenida y materiales de onboarding',
  ),
  footer_description: i18n.gettext(
    'Ten acceso a tasas diferenciadas, soporte técnico y muchos otros beneficios exclusivos para tu agencia y tus clientes. Es gratis.',
  ),
  invitations_title: i18n.gettext(
    '¿Ya ofreciste nuestro Checkout? Recibe tu identificación única y conviértete en un partner oficial y homologado.',
  ),
  LevelsInfo_Developers_OurLevels_certified: i18n.jsx.gettext(
    'Entre {0}$ 0{1} a {0}$ 9,999{1}/mes',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Silver: i18n.jsx.gettext(
    'Entre {0}$10,000{1} a {0}$39,999{1}/mes',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Gold: i18n.jsx.gettext(
    'Entre {0}$40.000{1} a {0}$99,999{1}/mes',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Platinum: i18n.jsx.gettext(
    'Entre de {0}$100.000{1} o más',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_certified_period: i18n.gettext('Trimestral'),
  LevelsInfo_Developers_OurLevels_Silver_period: i18n.gettext('Trimestral'),
  LevelsInfo_Developers_OurLevels_Gold_period: i18n.gettext('Trimestral'),
  LevelsInfo_Developers_OurLevels_Platinum_period: i18n.gettext('Trimestral'),
  Details_how_it_works: i18n.gettext(
    'Cómo funcionan los incentivos financieros',
  ),
  Details_how_it_works_one: i18n.jsx.gettext(
    'Los incentivos financieros ofrecidos por el programa garantizan a los desarrolladores certificados de México de los seguimientos de ventajas comerciales al integrar con las soluciones de Mercado Pago.',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Details_how_it_works_two: i18n.jsx.gettext('{0}Para ti{1}', {
    tags: {
      0: '<b>',
      1: '</b>',
    },
  }),
  Details_how_it_works_three: i18n.jsx.gettext(
    'Ventaja para ti por cada nueva cuenta de cliente que integres con nosotros',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Levels_first_text_our_levels: i18n.jsx.gettext(
    `A todos los desarrolladores que participan en nuestro programa les damos una identificación única llamada {3} Integrator ID {4}. Así, podemos identificar sus integraciones y darles acceso a los beneficios del programa.{2}{2}
      Te pedimos que agregues tu Integrator ID a tus integraciones, ya sean desarrollos propios o implementación de checkout para tiendas creadas en las siguientes plataformas de e-commerce: WooCommerce, PrestaShop, Adobe Commerce y VTEX.{2}{2}
      En caso de que la integración sea en una Plataforma distinta a las mencionadas anteriormente, dejá el registro de la misma en {0} este formulario.{1} {2}{2}
      Si quieres saber más acerca de cómo usar el Integrator ID, {5}accede a nuestra documentación.{6}{2}{2}
      Todos las integraciones eligibles al pago serán validadas semanalmente. Si el pago es aprobado, se aplicará la tasa preferencial y el depósito de la comisión se realizará el mismo día, directamente en tu cuenta de Mercado Pago.`,
    {
      tags: {
        0: '<a class="link" href="https://docs.google.com/forms/d/e/1FAIpQLSd0DRz3mC1eEiW9PFlE6SQfmwKjGfSwpbdwYzQ_zd80FLbciQ/viewform" target="_blank">',
        1: '</a>',
        2: '<br>',
        3: '<b>',
        4: '</b>',
        5: '<a class="link" href="https://www.mercadopago.com.mx/developers/es/docs/checkout-pro/additional-content/integration-metrics" target="_blank">',
        6: '</a>',
      },
    },
  ),
  LevelsInfo_OurLevels_certified: i18n.jsx.gettext(
    'Entre {0}$0{1} a {0}$9,999{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_Silver: i18n.jsx.gettext(
    'Entre {0}$10,000{1} a {0}$39,999{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_Gold: i18n.jsx.gettext(
    'Entre {0}$40,000{1} a {0}$99,999{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_Platinum: i18n.jsx.gettext(
    'Entre {0}$100,000{1} o más',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Levels_title: i18n.gettext('Cómo garantizar los incentivos financieros'),
  Developers_volver_importante: i18n.jsx.gettext('', {
    tags: {
      0: '<b>',
      1: '</b>',
    },
  }),
  LevelsInfo_OurLevels_certified_period: i18n.gettext('Trimestral'),
  LevelsInfo_OurLevels_Silver_period: i18n.gettext('Trimestral'),
  LevelsInfo_OurLevels_Gold_period: i18n.gettext('Trimestral'),
  LevelsInfo_OurLevels_Platinum_period: i18n.gettext('Trimestral'),
  membershipForm_personalTitle: i18n.gettext('Datos de la agencia'),
  membershipForm_companyName: i18n.gettext('Nombre de la agencia'),
  membershipForm_companyName_label: i18n.gettext(
    'Ingresa el nombre de la agencia',
  ),
  membershipForm_siteUrl: i18n.gettext('Ingresa el sitio de la agencia'),
  Introduction_title: i18n.gettext(
    'Aumenta tus resultados como desarrollador autónomo participando de nuestro Partners Program',
  ),
  Footnotes_disclaimer_one: i18n.gettext(''),
  Footnotes_disclaimer_doc: i18n.jsx.gettext('', {
    tags: {
      0: '<a class="footnotes--link" href="https://Recompensas%20.pdf" target="_blank">',
      1: '</a>',
    },
    wrapper: 'span',
  }),
  Footnotes_disclaimer_two: i18n.gettext(
    '¹ Este beneficio está disponible a partir del nivel Silver.',
  ),
  LevelsInfo_agencies_title: i18n.gettext(
    'Conoce las ventajas del Partners Program',
  ),
  Benefits_agencies_commercial_title: i18n.gettext('Comercial'),
  Footer_agencies_title: i18n.gettext(
    'Conviertete en una agencia partner de Mercado Pago',
  ),
  LevelsInfo_developers_title: i18n.gettext(
    'Conoce las ventajas del Partners Program para desarrolladores certificados',
  ),
});

export default textsMx;
