/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable no-negated-condition */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';
import { Button } from '@andes/button';
import { Card, CardContent } from '@andes/card';
import { CarouselSnapped, CarouselSnappedSlide } from '@andes/carousel-snapped';

const SuccessCases = ({ i18n, isMobile, successCases }) => {
  const [shortTexts, setShortTexts] = useState([]);

  useEffect(() => {
    setShortTexts(
      successCases.map((successCase) => ({
        description: `${successCase.description.substring(0, 217)}...`,
        isExpand: false,
      })),
    );
  }, []);

  const handleShortText = (successCase, index) => (
    <>
      {successCase.description.length < 217 || shortTexts.length < 1 ? (
        <p className="success-cases--card-description">
          {successCase.description}
        </p>
      ) : (
        <>
          <span className="success-cases--card-description">
            {shortTexts[index]?.isExpand
              ? successCase.description
              : shortTexts[index]?.description}
          </span>
          <Button
            className="success-cases--card-button"
            hierarchy="transparent"
            onClick={() =>
              setShortTexts(
                shortTexts.map((el, i) => {
                  if (index === i) {
                    return {
                      isExpand: !el.isExpand,
                      description: el.description,
                    };
                  }

                  return el;
                }),
              )
            }
          >
            {shortTexts[index]?.isExpand
              ? i18n.gettext('Ver menos')
              : i18n.gettext('Ver más')}
          </Button>
        </>
      )}
    </>
  );

  // Función para escapar el contenido HTML
  const createMarkup = (content) => {
    const video = content.split('video-')[1];

    return { __html: video };
  };

  return (
    <section className="success-cases-container">
      <div className="success-cases">
        <Image
          src="agencies/miscelanea.svg"
          alt=""
          className="success-cases-img"
        />
        <h2 className="success-cases-title">
          {i18n.gettext(
            'Conoce las historias de las empresas que ya son parte del programa',
          )}
        </h2>
        <CarouselSnapped
          srLabel="Success cases"
          arrows={!isMobile ? { visibility: 'always' } : false}
          pagination={!isMobile ? false : { mode: 'light' }}
          mobile={isMobile}
          spacing={24}
          slidesPerView={isMobile ? 1 : 2}
        >
          {successCases.map((successCase, index) => (
            <CarouselSnappedSlide key={successCase.id}>
              <div className="success-cases--card">
                <Card
                  animated
                  className={`success-cases--card ${
                    successCase.description.includes('video-')
                      ? 'has-video'
                      : 'no-video'
                  }`}
                >
                  <CardContent className="success-cases--content">
                    {successCase.description.includes('video-') ? (
                      <>
                        <div className="success-cases--card-title video-present">
                          <Image
                            src={`agencies/${successCase.img}`}
                            alt={successCase.partner}
                            className="success-cases--card-image"
                          />
                          <p className="success-cases--card-title-info">
                            {successCase.partner}
                          </p>
                        </div>
                        <div
                          className="success-cases--card-video"
                          dangerouslySetInnerHTML={createMarkup(
                            successCase.description,
                          )}
                        />
                      </>
                    ) : (
                      <>
                        {handleShortText(successCase, index)}
                        <div className="success-cases--card-title no-video">
                          <Image
                            src={`agencies/${successCase.img}`}
                            alt={successCase.partner}
                            className="success-cases--card-image"
                          />
                          <p className="success-cases--card-title-info">
                            {successCase.partner}
                          </p>
                        </div>
                        {!successCase.description.includes('video-') && (
                          <p className="success-cases--card-author">
                            {successCase.author}
                          </p>
                        )}
                      </>
                    )}
                  </CardContent>
                </Card>
              </div>
            </CarouselSnappedSlide>
          ))}
        </CarouselSnapped>
      </div>
    </section>
  );
};

SuccessCases.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  successCases: PropTypes.arrayOf(
    PropTypes.shape({
      partner: PropTypes.string,
      description: PropTypes.string,
      author: PropTypes.string,
      img: PropTypes.string,
      integrator_id: PropTypes.string,
      site: PropTypes.string,
    }),
  ),
};

export default SuccessCases;
