import React from 'react';
import PropTypes from 'prop-types';

const PreFooter = ({ i18n, background }) => (
  <section className={`prefooter ${background}`}>
    <p className="prefooter--disclaimer">
      {i18n.gettext(
        '*Este beneficio está disponible a partir del nivel Silver.',
      )}
    </p>
  </section>
);

PreFooter.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  background: PropTypes.string,
};

export default PreFooter;
