import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonText } from '@andes/button';
import ChevronRight20 from '@andes/icons/ChevronRight20';

import Tracker from '../../../../../Utils/tracker';

const LevelsContent = ({ i18n, lang, melidataPath }) => (
  <div className="levels--content">
    <h2 className="levels--content-title">
      {i18n.gettext(
        'Mientras más progresas en el Programa de Partners, más beneficios tienes',
      )}
    </h2>
    <p className="levels--content-details">
      {i18n.gettext(
        'El programa tiene 4 niveles con beneficios que se adaptan a la realidad de tu agencia y a tu portfolio de clientes.',
      )}
    </p>
    <Button
      className="levels--content-action"
      href={`/partners/agencies/${lang}/levels`}
      hierarchy="transparent"
      onClick={() => {
        Tracker.trackAnalyticsEvent({
          label: 'ACQUISITION_LANDING_LEVELS_ACTION_CLICK',
        });
        Tracker.melidataTrack(melidataPath, {
          event: 'ACQUISITION_LANDING_LEVELS_ACTION_CLICK',
        });
      }}
    >
      <ButtonText>{i18n.gettext('Conocer más')}</ButtonText>
      <ChevronRight20 color="light-blue-2" stroke="light-blue-2" />
    </Button>
  </div>
);

LevelsContent.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  lang: PropTypes.string,
  melidataPath: PropTypes.string,
};

export default LevelsContent;
