import React from 'react';
import PropTypes from 'prop-types';

import Button from '@andes/button';
import { Image } from 'nordic/image';

import Tracker from '../../../Utils/tracker';
import getTextsByCountry from '../../../../texts/getTexts';

const Steps = ({ i18n, analyticsLabel, lang, country, melidataPath }) => (
  <section className="step-certification-main-container">
    <div className="step-certification-container">
      <Image
        src="agencies/miscelanea.svg"
        alt=""
        className="step-certification-img"
      />
      <h2 className="step-certification-header">
        {i18n.gettext('Descubre la facilidad de convertirte en partner')}
      </h2>
      <div className="step-certification-container-body">
        <div className="container-lines">
          <Image src="common/steps/step_1.svg" alt="" />
          <span className="line" />
          <Image src="common/steps/step_2.svg" alt="" />
          <span className="line" />
          <Image src="common/steps/step_3.svg" alt="" />
          <span className="line" />
          <Image src="common/steps/step_4.svg" alt="" />
        </div>
        <div className="container-text">
          <p className="text-box">
            {i18n.gettext(
              'Haz tu inscripción gratis vía formulario online disponible en el sitio.',
            )}
          </p>
          <p className="text-box">
            {i18n.gettext(
              'Tendrás una reunión para conocer los detalles del programa.',
            )}
          </p>
          <p className="text-box">
            {getTextsByCountry(country, 'steps_3', i18n)}
          </p>
          <p className="text-box">
            {i18n.gettext(
              'Empieza a aprovechar los beneficios del programa, como nuestro Centro de Partners y nuestro soporte técnico prioritario.',
            )}
          </p>
        </div>
      </div>
      <Button
        className="step-action-button"
        href={`/partners/agencies/${lang}/subscribe`}
        onClick={() => {
          Tracker.trackAnalyticsEvent({ label: analyticsLabel });
          Tracker.melidataTrack(melidataPath, { event: analyticsLabel });
        }}
      >
        {i18n.gettext('Quiero inscribir mi agencia')}
      </Button>
    </div>
  </section>
);

Steps.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  analyticsLabel: PropTypes.string,
  lang: PropTypes.string,
  country: PropTypes.string,
  melidataPath: PropTypes.string,
};

export default Steps;
