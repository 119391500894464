import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';
import { Card, CardContent } from '@andes/card';

const PaymentItem = ({ image, title, description, styles }) => (
  <Card className="payment-solution__card" paddingSize={24}>
    <CardContent>
      <Image
        className="payment-solution__card-img"
        src={`agencies/paymentSolution/${image}?v=1`}
        alt={description}
      />
      <div className="payment-solution__card--content">
        <h3 className="payment-solution__card--content-title">{title}</h3>
        <p className={`payment-solution__card--content-description ${styles}`}>
          {description}
        </p>
      </div>
    </CardContent>
  </Card>
);

PaymentItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  styles: PropTypes.string,
};

export default PaymentItem;
