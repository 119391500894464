import React from 'react';
import PropTypes from 'prop-types';

import PaymentItem from '../PaymentItem';

const PaymentList = ({ paymentSolution }) => (
  <section className="payment-solution--container-section">
    {paymentSolution.map((payment) => (
      <PaymentItem
        key={payment.id}
        title={payment.title}
        description={payment.description}
        image={payment.image}
        styles={payment.className}
      />
    ))}
  </section>
);

PaymentList.propTypes = {
  paymentSolution: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      image: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

export default PaymentList;
