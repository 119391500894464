const clientBenefits = (i18n, country, lang) => [
  {
    id: '0',
    title: i18n.gettext('Negociación de tasas y condiciones especiales'),
    description: i18n.gettext(
      'Tus clientes recibirán las mejores condiciones para el uso del checkout de Mercado Pago.',
    ),
  },
  {
    id: '1',
    title: i18n.gettext('Soporte priorizado'),
    description: i18n.gettext(
      'Quien integra con vos también va a tener atención preferencial para dudas técnicas de integración.',
    ),
  },
  {
    id: '2',
    title: i18n.gettext('La mayor tasa de aprobación en pagos online'),
    description: i18n.gettext(
      'Somos una empresa con más de 20 años en el mercado de operaciones online en América Latina.',
    ),
  },
  {
    id: '3',
    title: i18n.gettext('Las normas más altas de seguridad online'),
    description: i18n.jsx.gettext(
      'Cumplimos con la {0}PCI DSS{1}, una de las normas más exigentes de seguridad de la industria de pagos.',
      {
        tags: {
          0: `<a class="benefit--link" href="https://www.mercadopago.com.${country}/developers/${lang}/docs/security/pci" target="_blank">`,
          1: '</a>',
        },
        wrapper: 'span',
      },
    ),
    sites: ['MLM'],
  },
];

const getClientBenefits = (i18n, siteId, country, lang) =>
  clientBenefits(i18n, country, lang).filter(
    (item) => !item.sites || item.sites.includes(siteId.toUpperCase()),
  );

export { getClientBenefits };
