import Avatar24 from '@andes/icons/Avatar24';
import Settings24 from '@andes/icons/Settings24';
import Calendar24 from '@andes/icons/Calendar24';
import Edit24 from '@andes/icons/Edit24';
import StarOutlined24 from '@andes/icons/StarOutlined24';
import Payment24 from '@andes/icons/Payment24';
import Wallet24 from '@andes/icons/Wallet24';

const benefits = (i18n, country) => [
  {
    id: '0',
    icono: <Avatar24 />,
    title: i18n.gettext('Visibilidad'),
    description: i18n.jsx.gettext(
      'Promueve tus servicios en nuestra {0}Central de partners{1}.*',
      {
        tags: {
          0: `<a class="benefit--link" href="https://centrodepartners.mercadoli${
            country === 'br' ? 'v' : 'b'
          }re.com.${country}/search/${
            country === 'br' ? 'pagamentos' : 'pagos'
          }?utm_source=home/section-categories" target="_blank">`,
          1: '</a>',
        },
        wrapper: 'span',
      },
    ),
    tooltip: i18n.gettext(
      '*Este beneficio está disponible a partir del nivel Silver.',
    ),
    className: 'xextra-large-width',
    sites: ['MLB', 'MLA'],
  },
  {
    id: '1',
    icono: <Avatar24 />,
    title: i18n.gettext('Mayor exposición'),
    description: i18n.jsx.gettext(
      'Promueve tus servicios en nuestra {0}Central de partners{1}.*',
      {
        tags: {
          0: `<a class="benefit--link" href="https://centrodepartners.mercadoli${
            country === 'br' ? 'v' : 'b'
          }re.com.${country}/search/${
            country === 'br' ? 'pagamentos' : 'pagos'
          }?utm_source=home/section-categories" target="_blank">`,
          1: '</a>',
        },
        wrapper: 'span',
      },
    ),
    tooltip: i18n.gettext(
      '*Este beneficio está disponible a partir del nivel Silver.',
    ),
    className: 'xextra-large-width',
    sites: ['MLM'],
  },
  {
    id: '2',
    icono: <Settings24 />,
    title: i18n.gettext('Soporte'),
    description: i18n.gettext(
      'Tienes atención con prioridad de nuestro equipo especializado.',
    ),
    className: 'extra-large-width',
  },
  {
    id: '3',
    icono: <Calendar24 />,
    title: i18n.gettext('Eventos'),
    description: i18n.gettext('Contáctate con especialistas en ventas online.'),
    className: 'medium-width',
    sites: ['MLB', 'MLM'],
  },
  {
    id: '4',
    icono: <Edit24 />,
    title: i18n.gettext('Capacitaciones'),
    description: i18n.gettext(
      'Te capacitas en nuestro portfolio de productos.',
    ),
    className: 'extra-small-width',
  },
  {
    id: '5',
    icono: <StarOutlined24 />,
    title: i18n.gettext('Novedades'),
    description: i18n.gettext(
      'Entérate de las actualizaciones y testea nuestros productos primero.',
    ),
    className: 'xxextra-large-width',
  },
  {
    id: '6',
    icono: <Payment24 />,
    title: i18n.gettext('Comisiones'),
    description: i18n.gettext(
      'Recibe dinero en base a las ventas de tus clientes.',
    ),
    className: 'xextra-small-width',
    sites: ['MLB', 'MLA'],
  },
  {
    id: '7',
    icono: <Payment24 />,
    title: i18n.gettext('Bonificaciones'),
    description: i18n.gettext(
      'Recibe dinero en base a las ventas de tus clientes.',
    ),
    className: 'xextra-small-width',
    sites: ['MLM'],
  },
  {
    id: '8',
    icono: <Wallet24 />,
    title: i18n.gettext('Bonus'),
    description: i18n.gettext(
      'Recibes comisiones extras en fechas comerciales especiales.',
    ),
    className: 'small-width',
    sites: ['MLB', 'MLM'],
  },

  {
    id: '9',
    image: 'discord_image.svg',
    imgClassName: 'big-img',
    title: i18n.gettext('Comunidad en Discord'),
    description: i18n.gettext(
      'Haga parte de nuestra comunidad de Partners en Discord.',
    ),
    className: 'extra-large-width',
    sites: ['MLM'],
  },
];

const getBenefits = (i18n, siteId, country) =>
  benefits(i18n, country).filter(
    (item) => !item.sites || item.sites.includes(siteId.toUpperCase()),
  );

export { getBenefits };
