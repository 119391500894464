import React from 'react';

import { Image } from 'nordic/image';

const LevelsImage = () => (
  <Image
    className="levels--image"
    src="agencies/levels/levels_image.png?v=1"
    alt=""
    // onContextMenu={() => false}
  />
);

export default LevelsImage;
