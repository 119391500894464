import React from 'react';

import { Image } from 'nordic/image';

const ClientBenefitsImage = () => (
  <Image
    className="clients--benefits--image"
    src="agencies/client_benefits_image.png?v=1"
    alt=""
    // onContextMenu={() => false}
  />
);

export default ClientBenefitsImage;
